<template>
  <div class="travel-tip-card">
    <div class="tag">
      <p :class="{'green-bg': travelTip.state == 'PUBLISHED'}">{{states.find(state => state.code == travelTip.state).name}}</p>
    </div>
    <div class="travel-tip-image-wrapper">
      <img v-if="!travelTip.titlePicture || !travelTip.titlePicture.fileName" src="../../assets/svg/ManageTraveltipDumyImage.svg" class="tip-dummy-picture">
      <figure v-else v-lazyload>
          <div class="loading-circle position-absolute"><div></div><div></div><div></div><div></div></div>
          <img class="picture" :data-url="imgUrl + travelTip.titlePicture.fileName.slice(0, travelTip.titlePicture.fileName.lastIndexOf('.')) + '-dimensions-640pxX480px' + travelTip.titlePicture.fileName.slice(travelTip.titlePicture.fileName.lastIndexOf('.'))">
      </figure>
    </div>
    <div class="trip-title-wrapper">
      <div class="first-line d-flex justify-content-between align-items-start">
        <div>{{ campaignTitle }}</div>
        <div id="length" class="text-right text-nowrap pl-3">{{ $tc("general.dayAmmount", travelTip.itineraryDays.length) }}</div>
      </div>
      <span class="travel-tip-difficulty">{{ getDifficulty(travelTip) }}</span>
      <p class="travel-tip-title" :data-title="chosenLocalization.title">{{ chosenLocalization && chosenLocalization.title }}</p>
      <p class="travel-tip-short-info">{{ chosenLocalization.shortInfo }}</p>
    </div>
    <div style="position: relative; ">
      <div style="position: absolute; top: 0; width: 100%; top: 0">
        <div style="position: relative">
          <div class="trip-cut-left"></div>
          <div class="trip-line"></div>
          <div class="trip-cut-right"></div>
        </div>
      </div>
    </div>
    <div class="bottom-part d-flex flex-column w-100">
      <div class="trip-icons">
        <div data-transferName="Chůze" class="transfer-wrapper" v-if="this.transfers && this.transfers.has('WALK')"><img src="@/assets/svg/icon-walking.svg" alt="walk" ></div>
        <div data-transferName="Letadlo" class="transfer-wrapper" v-if="this.transfers && this.transfers.has('FLIGHT')"><img src="@/assets/svg/icon-plane.svg" alt="flight" ></div>
        <div data-transferName="Autobus" class="transfer-wrapper" v-if="this.transfers && this.transfers.has('BUS')"><img src="@/assets/svg/icon-bus.svg" alt="bus" ></div>
        <div data-transferName="Loď" class="transfer-wrapper" v-if="this.transfers && this.transfers.has('BOAT')"><img src="@/assets/svg/icon-boat.svg" alt="boat" ></div>
        <div data-transferName="Vlak" class="transfer-wrapper" v-if="this.transfers && this.transfers.has('TRAIN')"><img src="@/assets/svg/icon-train.svg" alt="train" ></div>
        <div data-transferName="Auto" class="transfer-wrapper" v-if="this.transfers && this.transfers.has('CAR')"><img src="@/assets/svg/icon-car.svg" alt="car" ></div>
        <div data-transferName="Kolo" class="transfer-wrapper" v-if="this.transfers && this.transfers.has('BIKE')"><img src="@/assets/svg/icon-bike.svg" alt="bike" ></div>
        <div data-transferName="MHD" class="transfer-wrapper" v-if="this.transfers && this.transfers.has('PUBLIC_TRANSPORT')"><img src="@/assets/svg/icon-public-transport.svg" alt="public transport" ></div>
        <div data-transferName="Koloběžka" class="transfer-wrapper" v-if="this.transfers && this.transfers.has('SCOOTER')"><img src="@/assets/svg/icon-scooter.svg" alt="scooter" ></div>
        <div data-transferName="Inline brusle" class="transfer-wrapper" v-if="this.transfers && this.transfers.has('ROLLER_SCATE')"><img src="@/assets/svg/icon-rollerscate.svg" alt="rollerscate" ></div>
        <div data-transferName="Lanovka" class="transfer-wrapper" v-if="this.transfers && this.transfers.has('CABLE_CAR')"><img src="@/assets/svg/icon-cablecar.svg" alt="cablecar" ></div>
      </div>
      <div class="d-flex countries">
        <div v-for="country in getAllCountriesInTravelTip(travelTip)" :key="country.nameEnglish">{{ country[countryLocalization] }}</div>
      </div>
      <div class="profile">
        <div v-if="travelTip.author.avatarFileName" class="image" v-bind:style="{ backgroundImage: 'url(' + avatarImgUrl + travelTip.author.avatarFileName +')'}"></div>
        <div v-else class="initials">{{ initials }}</div>
        <p class="name">{{ travelTip.author.userName }}</p>
      </div>
      <div class="localizations">
        <div class="localization" v-for="localization in sortedLocalizations" :key="localization.language"
          :class="{'active': chosenLocalizationCode == localization.language, 'published': localization.published, 'original-language': localization.language == travelTip.originalLanguage}"
          @click.stop="setChosenLocalization(localization.language)">{{ localization.language }}
        </div>
        <div class="localization pink-bg" v-for="language in manageCampaignMissingTravelTipLocalizations" :key="language">{{ language }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {CLEVERTRIP_API_URL, CLEVERTRIP_IMG_URL} from "@/definitions";
import LazyLoadDirective from "@/components/LazyLoadDirective.js";
import sharedUtils from "../../utils/sharedUtils";

export default {
  name: "TravelTip",
  props: {
    travelTip: null,
    campaignLocalizationLanguages: [],
  },
  directives: {
    lazyload: LazyLoadDirective
  },

  data() {
    return {
      avatarImgUrl: CLEVERTRIP_API_URL + "/avatar/downloadFile/",
      imgUrl: CLEVERTRIP_IMG_URL,
      transfers: null,
      chosenLocalizationCode: null,
      sortedLocalizations: null,
    }
  },
  created() {
    this.getTransfers();

    const tripLocalizationsLanguages = this.travelTip.localizations.map(loc => loc.language);
    if (tripLocalizationsLanguages.includes(this.preferredLanguage)) {
      this.chosenLocalizationCode = this.preferredLanguage
    } else if (this.preferredLanguage == 'cs' && tripLocalizationsLanguages.includes('sk')) {
      this.chosenLocalizationCode = 'sk'
    } else {
      this.chosenLocalizationCode = this.travelTip.originalLanguage
    }

    this.sortedLocalizations = [...this.travelTip.localizations].sort((a, b) => {
      if (a.language == this.chosenLocalizationCode) return -1
      else if (b.language === this.chosenLocalizationCode) return 1
      else return b.language.localeCompare(a.language)
    })
  },
  
  computed: {
    manageCampaignMissingTravelTipLocalizations() {
      if (!this.campaignLocalizationLanguages) return []
      const campaignLanguagesSet = new Set(this.campaignLocalizationLanguages);
      const travelTipLanguages = this.travelTip.localizations.map(loc => loc.language) 
      if (!travelTipLanguages.some(language => campaignLanguagesSet.has(language))) {
        return this.campaignLocalizationLanguages
      }
      return []
    },
    campaignTitle() {
      if (!this.travelTip.campaign) return
      const campaignLocalization = this.travelTip.campaign.localizations?.find(loc => loc.language === this.chosenLocalizationCode)
      return campaignLocalization ? campaignLocalization.title : this.travelTip.campaign.localizations[0]?.title
    },
    countryLocalization() {
      return this.$store.getters.preferredLanguage == "en" ? "nameEnglish" : "nameCzech"
    },
    states() {
      return [
        {code: 'IN_PROGRESS', name: this.$t("admin.inProgress")},
        {code: 'TO_REWORK', name: this.$t("admin.toRework")},
        {code: 'TO_CONTROL', name: this.$t("admin.toControl")},
        {code: 'TO_APPROVE', name: this.$t("admin.toApprove")},
        {code: 'PUBLISHED', name: this.$t("admin.published")},
        {code: 'NOT_PUBLISHED', name: this.$t("admin.notPublished")},
        {code: 'DECLINED', name: this.$t("admin.declined")},
      ]
    },
    preferredLanguage() {
      return this.$store.state.preferredLanguage
    },

    chosenLocalization() {
      return this.travelTip.localizations.find(localization => localization.language === this.chosenLocalizationCode)
    },
    initials() {
      return sharedUtils.extractInitials(this.travelTip.author.userName)
    },
  },

  methods: {
    setChosenLocalization(languageCode) {
      this.chosenLocalizationCode = languageCode
    },
    getTransfers() {
      let transfers = new Set()

      this.travelTip.itineraryDays.forEach(itineraryDay => {
        itineraryDay.itineraryItems.forEach(itineraryItem => {
          itineraryItem.transferTypeValues.forEach(transferTypeValue => {
            transfers.add(transferTypeValue.transferType)
          })
        })
      })

      this.transfers = transfers
    },
    getDifficulty() {
      return sharedUtils.getDifficultyTextFromTravelTip(this.travelTip)
    },
    getAllCountriesInTravelTip(travelTip) {
      let countriesInTravelTip = []

      travelTip.itineraryDays.forEach(itineraryDay => {
        itineraryDay.itineraryItems.forEach(itineraryItem => {
          itineraryItem.countries.forEach(country => {
            if (!countriesInTravelTip.some(c => c.nameEnglish == country.nameEnglish)) {
              countriesInTravelTip.push(country)
            }
          })
        })
      })

      return countriesInTravelTip
    }
  }
}
</script>

<style lang="scss">
    @import '@/scss/variables';


.travel-tip-card {
  background-color: $tr-white-alt;
  max-width: 410px;
  overflow: hidden;
  z-index: 0;
  position: relative;

  figure {
    position: relative;
    max-height: 220px;
    margin: 0;

    .picture {
      width: 100%;
      aspect-ratio: 4/3;
      object-fit: cover;
      transition: 0.1s ease-in;
      transition-property: transform, box-shadow;
    }
  }


  .tag {
    position: absolute;
    z-index: 1000;
    left: 0;
    top: 20px;

    
    p {
      background-color: $tr-black;
      padding: 5px 10px;
      line-height: 20px;
      text-align: center;
      color: $tr-white;
      
      &.green-bg {
        background-color: $tr-green;
      }
    }

    &.purchasable {
      top: 60px;
      
      p {
        background-color: $tr-gray;
      }
    }
  }

  .travel-tip-image-wrapper {
    margin: 20px;
    width: calc(100% - 40px);
    overflow: hidden;

    .tip-dummy-picture {
      width: 100%;
      max-height: 220px;
      aspect-ratio: 4/3;
    }
  }

  .travel-tip-image-wrapper img, .travel-tip-image-wrapper .imgdiv {
    transition: all .2s ease-in-out;
  }

  &:hover .travel-tip-image-wrapper img, &:hover .travel-tip-image-wrapper .imgdiv {
    transform: scale(1.2);
  }

  .trip-title-wrapper {
    padding-left: 20px;
    padding-right: 20px;
    height: 136px;

    .travel-tip-difficulty {
      color: $tr-gray;
    }

    .first-line {
      display: flex;
      align-items: center;
      margin-top: 10px;
    }

    .travel-tip-title {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin-top: 10px;
      margin-bottom: 0px;
      font-size: 25px;
      position: relative;
      padding-bottom: 2px;

      &::after {
        display: none;
        position: absolute;
        left: -5px;
        top: 0;
        background-color: $tr-white-alt;
        content: attr(data-title);
        width: calc(100% + 10px);
        padding: 0 5px 5px;
        height: fit-content;
        z-index: 2;
      }

      &:hover {
        overflow: visible !important;
        &::after {
            display: block;
        }
      }
    }

    .travel-tip-short-info {
      color: $tr-gray;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .trip-cut-left {
    position: absolute;
    left: -15px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: $tr-white;
  }

  .trip-cut-right {
    position: absolute;
    right: -15px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: $tr-white;
  }

  .trip-line {
    position: absolute;
    border-top: 3px dashed $tr-light-gray;
    margin-left: 10px;
    width: calc(100% - 62px);
    top: 15px;
    left: 20px;
  }

  
  .bottom-part {
    padding-inline:20px;
    gap: 20px;
    margin-top: 40px;
    padding-bottom: 20px;

    .trip-icons {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      text-align: left;
      overflow-y: auto;
      overflow-x: hidden;
      height: 26px;
    
      .transfer-wrapper {
          position: relative
      }
    
      div::after {
          font-size: 14px;
          width: 84px;
          left: -28px;
          display: none;
          content: attr(data-transferName);
          position: absolute;
          text-align: center;
          top: -16px;
      }
      div:hover::after {
          display: block;
      }
    }
    
    .countries {
      height: 30px;
      gap: 10px;
      overflow-x: hidden;
      overflow-y: auto;
      flex-wrap: wrap;
    
      & > * {
        line-height: 20px;
        padding: 5px 10px;
        white-space: nowrap;
        background-color: $tr-black;
        color: $tr-white;
      }
    }
    
    .profile {
      display: flex;
      gap: 20px;
      align-items: center;
    
      .image, .initials {
        width: 35px;
        height: 35px;
        flex-shrink: 0;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border-radius: 50%;
      }
    
      .initials {
        background-color: $tr-green;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    
      .name {
        margin-bottom: 0;
      }
    }

    .localizations {
      display: flex;
      gap: 10px;
      align-items: center;

      .localization {
        padding: 2px 6px;
        border: 2px solid $tr-orange;
        background-color: $tr-white-alt;
        cursor: pointer;
        
        &:hover {
          background-color: $tr-light-gray;
        }
        
        &.pink-bg {
          background-color: $tr-pink;
          color: $tr-white;
          border: 2px solid $tr-pink;
          cursor: default;
        }

        &.active {
          background-color: $tr-black;
          color: $tr-white;
        }
        
        &.published {
          border: 2px solid $tr-green;
        }

        &.original-language {
          position: relative;

          &::after {
            content: "";
            display: block;
            position: absolute;
            bottom: -8px;
            left: 5%;
            width: 90%;
            height: 2px;
            background-color: $tr-black;
          }
        }
      }
    }
  }
}
</style>