<template>
  <div class="travel-tip-list-container grid font-weight-bold text-left m-0" :class="{'static-layout': manageCampaignTips}">
    <div v-for="(travelTip, index) in allTravelTipsSorted" :key="travelTip.id" class="travel-tip-container p-0"  @click="onTravelTipClick(travelTip.id)">
      <div v-if="manageCampaignTips" class="x" @click.prevent.stop="$emit('deleteTravelTip', travelTip.id)"></div>
      <div v-if="(manageCampaignTips || addTravelTipsToCampaign) && !travelTip.localizations.some(loc => campaignLocalizationLanguages.includes(loc.language))" class="exclamation-mark" v-tooltip.right-start="$t('admin.noCommonLocalizationWithCampaign')"></div>
      <div v-if="addTravelTipsToCampaign" class="cover-tip" @click.prevent.stop="$emit('addTravelTipConfirmed', travelTip)"></div>
      <div v-if="travelTip.toppable" class="position-absolute d-flex flex-column align-items-center" style="gap: 5px; right: 5px; top: 60px; z-index: 10; width: 50px">
        <div class="top-arrow" v-tooltip.left="travelTip.topDate > '1971-01-01T00:00:00Z' && index == 0 ? $t('admin.travelTipAlreadyOnTheTop') : $t('admin.topTravelTip')" :class="{'disabled': travelTip.topDate > '1971-01-01T00:00:00Z' && index == 0}" @click.stop="topTravelTip(travelTip.id, travelTip.topDate, index)"></div>
        <div class="untop-arrow" v-tooltip.left="travelTip.topDate < '1971-01-01T00:00:00Z' ? $t('admin.travelTipAlreadyUntopped') : $t('admin.untopTravelTip')" :class="{'disabled': travelTip.topDate < '1971-01-01T00:00:00Z'}" @click.stop="untopTravelTip(travelTip.id, travelTip.topDate)"></div>
      </div>
      <div v-if="manageCampaignTips || addTravelTipsToCampaign" class="preview-tip-icon" :class="{'to-top': addTravelTipsToCampaign}" @click.stop="openInNewTab(travelTip.id)"></div>
      <TravelTip :travel-tip="travelTip" :campaignLocalizationLanguages="campaignLocalizationLanguages"/>
    </div>
    <div v-if="manageCampaignTips" @click="$emit('addTravelTipModal')" class="p-0 add-tip-container">
      <div class="plus mx-auto" style="margin-block: 70px 50px"></div>
      <div class="text-center">{{ $t("admin.addTravelTipToCampaign") }}</div>
      <div class="cut-out">
        <div class="trip-cut-left"></div>
        <div class="trip-line"></div>
        <div class="trip-cut-right"></div>
      </div>
    </div>
  </div>
</template>

<script>
import TravelTip from "@/components/admin/TravelTip";
import TravelTipService from "@/services/TravelTipService";
import sharedUtils from "@/utils/sharedUtils";

export default {
  name: "TravelTipList",
  components: {TravelTip},
  props: {
    showOldTips: Boolean,
    travelTips: Array,
    savedTravelTips: Array,
    manageCampaignTips: Boolean,
    addTravelTipsToCampaign: Boolean,
    isTravelTipToppable: Boolean,
    campaignLocalizationLanguages: Array,
  },
  methods: {
    async topTravelTip(id, topDate, index) {
      if (topDate > '1971-01-01T00:00:00Z' && index == 0) return
      await TravelTipService.topTravelTip(id)
      .then(resp => this.$emit('updateTopDate', id))

    },
    async untopTravelTip(id, topDate) {
      if (topDate < '1971-01-01T00:00:00Z') return
      TravelTipService.untopTravelTip(id)   
      .then(resp => this.$emit('updateTopDate', id))   
    },
    onTravelTipClick(id) {
      if (!this.manageCampaignTips) {
        this.$router.push({ name: "adminManageTravelTip", params: { travelTipId: id } });
      }
    },
    openInNewTab(id) {
      const url = `#/admin/manageTravelTip/${id}`;
      window.open(url, '_blank');
    }
  },
  computed: {
    savedTravelTipIds() {
      if(!this.savedTravelTips) return
      return this.savedTravelTips.map(tip => tip.id)
    },
    allTravelTipsSorted: function () {
      if (!this.travelTips) return
      let allTravelTipsCopy = sharedUtils.cleanupTravelTips(this.travelTips);
      if (this.savedTravelTips) {
        allTravelTipsCopy = allTravelTipsCopy.map(tip => {
          if (this.savedTravelTipIds.includes(tip.id.toString())) {
            return {...tip, toppable: true}
          }
          return {...tip}
        })
      }
      allTravelTipsCopy.sort((a, b) => new Date(b.topDate) - new Date(a.topDate))
      if (this.showOldTips == false) {
        allTravelTipsCopy = allTravelTipsCopy.filter(tip => tip.titlePicture)
      }
      return allTravelTipsCopy
    }
  }
}
</script>

<style scoped lang="scss">
    @import '@/scss/variables';

.top-arrow, .untop-arrow {
  cursor: pointer;
  width: 50px;
  height: 50px;
  position: relative;
  z-index: 1;
  background-image: url("data:image/svg+xml,%3Csvg width='50' height='50' viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z' fill='%23222222'/%3E%3Cpath d='M25 17L31.0622 23.75H18.9378L25 17Z' fill='white'/%3E%3Crect x='22' y='23' width='6' height='10' fill='white'/%3E%3C/svg%3E%0A");

  &:hover {
    filter: brightness(1.4);filter: brightness(1.4);
  }
}

.top-arrow {

  &.disabled {
    cursor: default !important;
    background-image: url("data:image/svg+xml,%3Csvg width='50' height='50' viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_10_2)'%3E%3Cpath d='M25 0C11.1929 0 3.8147e-06 11.1929 3.8147e-06 25C3.8147e-06 38.8071 11.1929 50 25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0Z' fill='%23545454'/%3E%3Cg clip-path='url(%23clip1_10_2)'%3E%3Cpath d='M25.0002 17L25.3722 16.6659L25.0002 16.2517L24.6282 16.6659L25.0002 17ZM31.0623 23.75L31.0623 24.25L32.1834 24.25L31.4343 23.4159L31.0623 23.75ZM18.938 23.75L18.566 23.4159L17.8169 24.25L18.938 24.25L18.938 23.75ZM22.0001 23.75L22.5001 23.75L22.5001 23.25L22.0001 23.25L22.0001 23.75ZM22.0001 33L21.5001 33L21.5001 33.5L22.0001 33.5L22.0001 33ZM28.0001 33L28.0001 33.5L28.5001 33.5L28.5001 33L28.0001 33ZM28.0001 23.75L28.0001 23.25L27.5001 23.25L27.5001 23.75L28.0001 23.75ZM24.6282 17.3341L30.6903 24.0841L31.4343 23.4159L25.3722 16.6659L24.6282 17.3341ZM19.31 24.0841L25.3722 17.3341L24.6282 16.6659L18.566 23.4159L19.31 24.0841ZM22.0001 23.25L18.938 23.25L18.938 24.25L22.0001 24.25L22.0001 23.25ZM22.5001 33L22.5001 23.75L21.5001 23.75L21.5001 33L22.5001 33ZM28.0001 32.5L22.0001 32.5L22.0001 33.5L28.0001 33.5L28.0001 32.5ZM27.5001 23.75L27.5001 33L28.5001 33L28.5001 23.75L27.5001 23.75ZM31.0623 23.25L28.0001 23.25L28.0001 24.25L31.0623 24.25L31.0623 23.25Z' fill='%23A5A5A5'/%3E%3C/g%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_10_2'%3E%3Crect width='50' height='50' fill='white' transform='translate(50 50) rotate(-180)'/%3E%3C/clipPath%3E%3CclipPath id='clip1_10_2'%3E%3Crect width='16' height='18' fill='white' transform='translate(17 16)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  }
}
.untop-arrow {
  background-image: url("data:image/svg+xml,%3Csvg width='50' height='50' viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M25 2.18557e-06C11.1929 3.39263e-06 -3.39263e-06 11.1929 -2.18557e-06 25C-9.78513e-07 38.8071 11.1929 50 25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 9.78513e-07 25 2.18557e-06Z' fill='%23222222'/%3E%3Cpath d='M25 33L18.9378 26.25L31.0622 26.25L25 33Z' fill='white'/%3E%3Crect x='28' y='27' width='6' height='10' transform='rotate(180 28 27)' fill='white'/%3E%3C/svg%3E%0A");;

  &.disabled {
    cursor: default !important;
    background-image: url("data:image/svg+xml,%3Csvg width='50' height='50' viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_6_6)'%3E%3Cpath d='M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 -6.78525e-06 25 -4.37114e-06C11.1929 -1.95703e-06 1.95703e-06 11.1929 4.37114e-06 25C6.78525e-06 38.8071 11.1929 50 25 50Z' fill='%23545454'/%3E%3Cg clip-path='url(%23clip1_6_6)'%3E%3Cpath d='M24.9998 33L24.6279 33.3341L24.9998 33.7483L25.3718 33.3341L24.9998 33ZM18.9377 26.25L18.9377 25.75L17.8166 25.75L18.5657 26.5841L18.9377 26.25ZM31.062 26.25L31.434 26.5841L32.1831 25.75L31.062 25.75L31.062 26.25ZM27.9999 26.25L27.4999 26.25L27.4999 26.75L27.9999 26.75L27.9999 26.25ZM27.9999 17L28.4999 17L28.4999 16.5L27.9999 16.5L27.9999 17ZM21.9999 17L21.9999 16.5L21.4999 16.5L21.4999 17L21.9999 17ZM21.9999 26.25L21.9999 26.75L22.4999 26.75L22.4999 26.25L21.9999 26.25ZM25.3718 32.6659L19.3097 25.9159L18.5657 26.5841L24.6279 33.3341L25.3718 32.6659ZM30.69 25.9159L24.6279 32.6659L25.3718 33.3341L31.434 26.5841L30.69 25.9159ZM27.9999 26.75L31.062 26.75L31.062 25.75L27.9999 25.75L27.9999 26.75ZM27.4999 17L27.4999 26.25L28.4999 26.25L28.4999 17L27.4999 17ZM21.9999 17.5L27.9999 17.5L27.9999 16.5L21.9999 16.5L21.9999 17.5ZM22.4999 26.25L22.4999 17L21.4999 17L21.4999 26.25L22.4999 26.25ZM18.9377 26.75L21.9999 26.75L21.9999 25.75L18.9377 25.75L18.9377 26.75Z' fill='%23A5A5A5'/%3E%3C/g%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_6_6'%3E%3Crect width='50' height='50' fill='white'/%3E%3C/clipPath%3E%3CclipPath id='clip1_6_6'%3E%3Crect width='16' height='18' fill='white' transform='translate(33 34) rotate(180)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  }
}

.preview-tip-icon {
  background-image: url('data:image/svg+xml,<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23a)"><path d="M0 25c0 13.807 11.193 25 25 25s25-11.193 25-25S38.807 0 25 0 0 11.193 0 25Z" fill="%23222"/><path d="M15 16v-.5h-.5v.5h.5Zm0 19h-.5v.5h.5V35Zm19 0v.5h.5V35H34Zm-4.84-15.367-.366.339.678.734.367-.339-.678-.734ZM36 14h.5v-.5H36v.5Zm-.5 4.5v.5h1v-.5h-1Zm-4-5H31v1h.5v-1Zm-1.543 2H15v1h14.957v-1ZM14.5 16v19h1V16h-1Zm.5 19.5h19v-1H15v1Zm19.5-.5V20.043h-1V35h1Zm-4.66-14.633 6.5-6-.68-.734-6.5 6 .68.734ZM35.5 14v4.5h1V14h-1Zm.5-.5h-4.5v1H36v-1Z" fill="%23fff"/></g><defs><clipPath id="a"><path fill="%23fff" d="M0 0h50v50H0z"/></clipPath></defs></svg>');
  position: absolute;
  width: 50px;
  height: 50px;
  top: 170px;
  z-index: 1;
  right: 5px;
  background-color: $tr-black;
  border-radius: 50%;
  cursor: pointer;

  &.to-top {
    top: 5px;
  }

  &:hover {
    filter: brightness(1.4);
  }
}

.travel-tip-list-container {
  position: relative;
  max-width: 1810px;
  width: 100%;
  grid-gap: 50px 40px;
  margin-right: 40px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, auto));

  &.static-layout {
    grid-template-columns: repeat(auto-fill, minmax(330px, 330px));

    .travel-tip-container {
      cursor: default;
    }
  }

  .cursor-default {
    cursor: default !important;
  }

  .travel-tip-container, .add-tip-container {
    cursor: pointer;
    position: relative;
    .cover-tip {
      height: 100%;
      width: 100%;
      position: absolute;
      z-index: 1;

      &:hover {
        outline: 3px solid $tr-green;
      }
    }
  }
  .x {
    position: absolute;
    z-index: 1;
    width: 50px;
    height: 50px;
    top: 5px;
    right: 5px;

    &::after {
      width: 1px;
    }
    &::before {
      height: 1px
    }
  }
  .exclamation-mark {
    position: absolute;
    z-index: 2;
    width: 25px;
    height: 25px;
    top: -12px;
    left: -12px;
    background-color: $tr-pink;
    border-radius: 50%;
    background-size: 18px;
    background-repeat: no-repeat;
    background-position: center 4px;
    background-image:url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11 15h2l2-13H9z" fill="%23fff"/><circle cx="12" cy="20" r="2" fill="%23fff"/></svg>')
  }
  .add-tip-container {
    position: relative;
    border: 3px dashed $tr-light-gray; 
    height: 580px;

    .cut-out {
      position: absolute;
      top: 390px;
      width: 100%;
      height: 30px;

      .trip-cut-left, .trip-cut-right {
        position: absolute;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: $tr-white;
        border: 3px dashed $tr-light-gray;
      }
      .trip-cut-left {
        left: -15px;
  
        &::after {
          content: "";
          top: -3px;
          left: -6px;
          width: 15px;
          height: 30px;
          position: absolute;
          background-color: $tr-white;
        }
      }
  
      .trip-cut-right {
        right: -15px;
  
        &::after {
          content: "";
          top: -3px;
          right: -6px;
          width: 15px;
          height: 30px;
          position: absolute;
          background-color: $tr-white;
        }
      }
  
      .trip-line {
        position: absolute;
        border-top: 3px dashed $tr-light-gray;
        margin-left: 10px;
        width: calc(100% - 62px);
        top: 15px;
        left: 20px;
      }
    }

  }
}

</style>
